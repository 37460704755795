.modal.modal-angular{
    .modal-header{
        border-bottom: none;
        position: relative;
    }

    .modal-footer{
        border-top: none;
    }

    .modal-body{
        .separator{
            border-bottom: 1px solid rgba(220,220,220, .2);
            width: 100%;
            display: block;
            margin: 11px 0;
        }

        .image-container{
            width: 35px;
            overflow: hidden;
            margin: 0px auto;
            margin-bottom: 17px;

            img{
                width: 100%;
                vertical-align: top;
            }

            &.image-angular-cli{
                width: 44px;
                position: relative;
                top: 4px;
                margin-top: -9px;
            }
        }

        h4{
            padding-left: 0px;
            font-size: 16px;
            font-weight: 400;

            &.margin-top{
                margin-top: 15px;
            }

            i{
                color: #c5a47e;
            }
        }

        a.modal-button{
            display: inline-block;
            padding: 20px 15px;
            background-color: #fdfdfd;
            font-size: 14px;
            text-align: center;
            color: inherit;
            border-radius: 6px;
            transition: box-shadow 150ms ease-in;
            width: 130px;
            max-width: 130px;
            margin-bottom: 10px;
            font-weight: 100;
            border: 1px solid rgba(220,220,220, .4);

            &:nth-child(2n+1){
                margin-left: 10px;
                margin-right: 10px;
            }

            &.disabled{
                opacity: .5;
                pointer-events: none;
            }

            &:hover,
            &:focus{
                box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.2);
            }

            .product-type{
                font-size: 12px;
                font-weight: 600;
            }

            .price{
                font-size: 11px;
                font-weight: 100;

                span{
                    display: block;
                    font-size: 27px;
                    position: relative;
                    font-weight: 500;
                    margin-top: 3px;

                    i{
                        font-size: 10px;
                        position: absolute;
                        width: 8px;
                        top: 4px;
                    }
                }
            }

            .wrapper-card{
                position: relative;
            }
        }
    }
}

@media (min-width: 768px){
    .modal.modal-angular{
        .modal-dialog{
            width: 570px;
        }
    }
}
